import { UseQueryResult, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API } from '@/utils/apiConstants';

interface PostalCodeLookupQueryParams {
  countryId: string;
  postalCode: string;
  enabled: boolean;
}

interface PostalCodeLookupState {
  abbr: string;
  name: string;
  cities: string[];
  suburbs: string[];
}

interface PostalCodeLookupResponse {
  cities: string[];
  city: string;
  postal_code: string;
  state: PostalCodeLookupState;
  states: PostalCodeLookupState[];
  suburbs: string[];
}

const POSTAL_CODE_LOOKUP = 'POSTAL_CODE_LOOKUP';

export const usePostalCodeLookup = ({
  countryId,
  postalCode,
  enabled,
}: PostalCodeLookupQueryParams): UseQueryResult<PostalCodeLookupResponse> => {
  return useQuery<PostalCodeLookupResponse>({
    queryKey: [POSTAL_CODE_LOOKUP, countryId, postalCode],
    enabled: !!countryId && !!postalCode && !!enabled,
    queryFn: async (): Promise<PostalCodeLookupResponse> => {
      const { data } = await axios.get(
        `${API.baseEndpoint}/api/v1/countries/${countryId}/postal_codes/${postalCode}`,
      );
      return data;
    },
    staleTime: Infinity,
  });
};
