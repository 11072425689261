import {
  addressFormSchema,
  invoiceFormSchema,
  generatePinFormSchema,
  validatePinFormSchema,
} from '@/forms/fedex/schema';
import { Country } from '@/models/Address';
import { stepOptions } from '@/models/Fedex';

export enum FedexStepEnum {
  EULA = 'eula',
  FORM = 'form',
  INVOICE = 'invoice',
  PIN_CHOICE = 'pin-choice',
  VALIDATE_PIN = 'validate-pin'
}

export const changeStepAndSchema = (country: Country): Record<string, stepOptions> => ({
  [FedexStepEnum.FORM]: {
    prevStep: FedexStepEnum.EULA,
    schema: addressFormSchema(country),
    submitBtnText: 'next'
  },
  [FedexStepEnum.INVOICE]: {
    prevStep: FedexStepEnum.FORM,
    alternateStep: FedexStepEnum.PIN_CHOICE,
    schema: invoiceFormSchema,
    submitBtnText: 'connect'
  },
  [FedexStepEnum.PIN_CHOICE]: {
    prevStep: FedexStepEnum.INVOICE,
    alternateStep: FedexStepEnum.INVOICE,
    schema: generatePinFormSchema,
    submitBtnText: 'send code'
  },
  [FedexStepEnum.VALIDATE_PIN]: {
    prevStep: FedexStepEnum.PIN_CHOICE,
    alternateStep: FedexStepEnum.PIN_CHOICE,
    schema: validatePinFormSchema,
    submitBtnText: 'connect'
  }
});