import { ControlledInput } from '@/components/ControlledInput';
import { useFormContext } from 'react-hook-form';
import { ControlledSelect } from '@/components/ControlledSelect';
import { useCurrencies } from '@/hooks/useCurrencies';
import ChangeVerificationMethod from './ChangeVerificationMethod';
import { InvoiceFormValues } from './schema';
import { useMemo } from 'react';

interface FedexInvoiceFormProps {
  changeStep: () => void;
}

function FedexInvoiceForm({ changeStep }: FedexInvoiceFormProps) {
  const { control, formState: { defaultValues } } = useFormContext<InvoiceFormValues>();

  const { data: currencies } = useCurrencies();
  const currencyOptions = useMemo(() => {
    return (currencies ?? []).map((currency: string) => ({
      label: currency,
      value: currency,
    }));
  }, [currencies]);

  return (
    <div className="flex flex-col gap-y-6">
      <p className="text-lg">Provide Invoice Information</p>
      <p>
        Invoice information is also required to connect, unless this account has not received an
        invoice in the last 90 days.
      </p>
      <div className="flex flex-col gap-y-6">
        <div className="flex gap-x-4 w-full">
          <ControlledInput name="number" control={control} label="Invoice Number" required />
          <ControlledInput
            type="date"
            name="date"
            label="Invoice Date"
            control={control}
            required
            defaultValue={defaultValues?.date}
          />
        </div>
        <div className="flex w-[calc(50%_-_16px)]">
          <div className="w-2/3">
            <ControlledSelect
              name="currency"
              control={control}
              label="Currency"
              required
              options={currencyOptions}
              position="Lead"
              placeholder="Choose one"
            />
          </div>
          <ControlledInput
            type="number"
            name="amount"
            label="Amount"
            control={control}
            required
            position="Trail"
          />
        </div>
        <ChangeVerificationMethod
          buttonText="Receive a verification code via email or phone"
          changeStep={changeStep}
          message="Can’t verify? Try another option below."
        />
      </div>
    </div>
  );
}

export default FedexInvoiceForm;
