import { API } from '@/utils/apiConstants';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface useSetUpsCodeMutationParams {
  companyId: string;
  courierAccountId: string;
  code: string;
}

export const useSetUpsCodeMutation = () => {
  return useMutation({
    mutationFn: async ({
      companyId,
      courierAccountId,
      code,
    }: useSetUpsCodeMutationParams): Promise<string> => {
      return axios.post(
        `${API.baseEndpoint}/api/v1/companies/${companyId}/courier_accounts/${courierAccountId}/code`,
        { code },
      );
    },
  });
};
