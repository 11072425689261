import { ControlledInput } from '@/components/ControlledInput';
import { ControlledSelect } from '@/components/ControlledSelect';
import { Alert } from 'easyship-components';
import { useFormContext } from 'react-hook-form';
import { AddressFormValues } from './schema';
import { useCountries } from '@/hooks/useCountries';
import { Separator } from '@/components/Separator';
import { useEffect, useState } from 'react';
import { Country } from '@/models/Address';
import { getCountryRules } from '@/helpers/countryRules';
import { usePostalCodeLookup } from '@/hooks/usePostalCodeLookup';

function FedexAddressForm({ country }: { country: Country }) {
  const { control, watch, setValue } = useFormContext<AddressFormValues>();
  const postalCode = watch('postalCode');
  const selectedState = watch('state');

  const { data: countries } = useCountries();
  const { data: postalCodeLookupData } = usePostalCodeLookup({
    countryId: country.id,
    postalCode,
    enabled: new RegExp(country.postalCodeRegex || '').test(postalCode),
  });

  const stateOptions = (postalCodeLookupData?.states ?? []).map((state) => ({
    label: state.name,
    value: state.name,
  }));

  const [cityOptions, setCityOptions] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    const selectedStateObj = (postalCodeLookupData?.states ?? []).find(
      (state) => state.name === selectedState,
    );
    if (selectedStateObj) {
      const cityKey = countryRules.city.translationKey === 'City' ? 'cities' : 'suburbs';
      const cities = selectedStateObj?.[cityKey]
        ? selectedStateObj[cityKey].map((suburb) => ({
            label: suburb,
            value: suburb,
          }))
        : [];
      setCityOptions(cities);
    } else {
      setCityOptions([]);
    }
  }, [selectedState, postalCodeLookupData]);

  useEffect(() => {
    if (postalCodeLookupData?.state?.name) {
      setValue('state', postalCodeLookupData?.state?.name);
    }
    if (postalCodeLookupData?.city) {
      setValue('city', postalCodeLookupData?.city);
    }
  }, [postalCodeLookupData, setValue]);

  const countryRules = getCountryRules(country.alpha2);

  return (
    <div>
      <>
        <p className="text-lg mb-4">Authenticate your FedEx account</p>
        <p>You need to provide your FedEx account details to verify your account.</p>
        <Separator />
      </>
      <div className="flex flex-col gap-y-4">
        <ControlledInput
          name="nickname"
          control={control}
          label="Account Name"
          placeholder="e.g. MyCarrierAccount 01"
          statusText="The account name will be used to identify your account"
          required
        />

        <ControlledInput
          name="accountNumber"
          control={control}
          label="FedEx Account Number"
          placeholder="e.g. 670116009"
          required
        />

        <Alert>
          <p>The address below should be the same as your current FedEx account billing address.</p>
        </Alert>

        <div className="grid grid-cols-2 w-full gap-5">
          {countries && (
            <ControlledSelect
              control={control}
              name="countryCode"
              label="Country"
              options={countries.map((country) => {
                return {
                  value: country.alpha2,
                  label: country.name,
                };
              })}
              placeholder="Choose a country"
              required
              disabled={!!country.id}
              defaultValue={country.alpha2}
            />
          )}

          <ControlledInput
            name="postalCode"
            control={control}
            label={countryRules.postalCode.translationKey}
            required
          />
        </div>
        <div className="grid grid-cols-2 w-full gap-5">
          {countryRules.state.isDropdown ? (
            <ControlledSelect
              name="state"
              control={control}
              label="State/Province"
              options={stateOptions}
              disabled={stateOptions.length === 0}
              required
              placeholder="Select state"
            />
          ) : (
            <ControlledInput
              name="state"
              control={control}
              label={`State/Province ${!country.isStateRequired ? '(optional)' : ''}`}
              required
            />
          )}
          {countryRules.city.isDropdown ? (
            <ControlledSelect
              name="city"
              control={control}
              label={countryRules.city.translationKey}
              options={cityOptions}
              disabled={cityOptions.length === 0}
              required
              placeholder={`Select ${countryRules.city.translationKey}`}
            />
          ) : (
            <ControlledInput
              name="city"
              control={control}
              label={countryRules.city.translationKey}
              required
            />
          )}
        </div>

        <ControlledInput name="line1" control={control} label="Address Line 1" required />

        <ControlledInput name="line2" control={control} label="Address Line 2" />
      </div>
    </div>
  );
}

export default FedexAddressForm;
