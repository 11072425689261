import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  PathValue,
} from "react-hook-form";
import { Input, InputProps } from "easyship-components";

type ControlledInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Pick<
  ControllerProps<TFieldValues, TName>,
  "name" | "control" | "defaultValue"
> &
  Pick<
    InputProps,
    "label" | "placeholder" | "statusText" | "type" | "required" | "position"
  >;

export const ControlledInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  control,
  label,
  placeholder,
  statusText,
  type,
  required,
  position
}: ControlledInputProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={"" as PathValue<TFieldValues, TName>}
      render={({
        field: { ref, onChange, value, onBlur, disabled, name },
        fieldState,
      }) => (
        <Input
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          value={value}
          inputContainerRef={ref}
          label={label}
          type={type}
          status={!!fieldState.error ? "error" : "default"}
          statusText={
            fieldState.error?.message || (
              <p className="text-ink-100">{statusText}</p>
            )
          }
          placeholder={placeholder}
          required={required}
          position={position}
        />
      )}
    />
  );
};
