import { useToken } from '@/hooks/useToken';
import { createContext, useContext } from 'react';
import Cookies from 'js-cookie';
import { Outlet } from 'react-router-dom';
import { ErrorPage } from '@/pages/ErrorPage';
import { Pulse } from 'easyship-components';
import { postMessage } from '@/helpers/postMessage';
import AxiosInterceptor from '@/services/axiosRequestInterceptors';

interface AuthContextProps {
  credentials: string | undefined;
}

export const AuthContext = createContext<AuthContextProps>({
  credentials: undefined,
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const { data, isLoading } = useToken(token || '');

  if (!data || !data.token) return;

  const credentials = data.token;

  if (window.parent !== window) {
    postMessage('credentials', credentials);
  }

  Cookies.set('easyship-lyoc-credentials', credentials, { sameSite: 'none', secure: true });
  window.localStorage.setItem('redirectUrl', data.ESredirectUrl || '');

  if (isLoading) return <Pulse />;
  return (
    <AuthContext.Provider value={{ credentials }}>
      <AxiosInterceptor>{credentials ? <Outlet /> : <ErrorPage />}</AxiosInterceptor>
    </AuthContext.Provider>
  );
};
