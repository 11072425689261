import { ControlledInput } from '@/components/ControlledInput';
import { Button } from 'easyship-components';
import ChangeVerificationMethod from './ChangeVerificationMethod';
import useCountdown from '@/hooks/useCountdown';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { GeneratePinFormValues, ValidatePinFormValues } from './schema';
import { useFedexGeneratePinMutation } from '@/hooks/useFedexGeneratePinMutation';
import { toastError, toastSuccess } from '@/components/Toastify';
import axios from 'axios';

interface FedexValidatePinFormProps {
  changeStep: () => void;
  companyId: string;
  fedexCourierId: string;
}

function FedexValidatePinForm({
  changeStep,
  companyId,
  fedexCourierId,
}: FedexValidatePinFormProps) {
  const { start, timeLeft, isRunning } = useCountdown(60);
  const { mutate: generatePin, isPending: generatingPin } = useFedexGeneratePinMutation();

  useEffect(() => {
    if (!isRunning) {
      start();
    }
  }, [isRunning, start]);

  const { control, getValues } = useFormContext<ValidatePinFormValues | GeneratePinFormValues>();

  const validationOption = getValues('validation_option');

  function handleResendPinSuccess() {
    toastSuccess('A message was successfully resent');
    start();
  }

  function handleResendPinError(error: Error) {
    const message = axios.isAxiosError(error)
      ? (error.response?.data?.status ?? 'An error has occurred while adding the courier account')
      : 'An error has occurred while adding the courier account';
    toastError(message);
  }

  function handleResend() {
    generatePin(
      {
        companyId,
        courier: fedexCourierId,
        data: { validation_option: validationOption },
      },
      { onSuccess: handleResendPinSuccess, onError: handleResendPinError },
    );
  }

  return (
    <div className="flex flex-col gap-y-6">
      <p className="text-lg">Get Verification Code</p>
      <p>
        {validationOption === 'email'
          ? 'An email with a verification code was just sent to your email registered in FedEx.'
          : 'A text message with a verification code was just sent to your phone number registered in FedEx.'}
      </p>
      <div className="flex flex-col gap-y-6 w-full">
        <div>
          <div className="w-1/2">
            <ControlledInput
              name="pin_code"
              label="Enter 6-digit code"
              control={control}
              required
            />
          </div>
          <p className="text-ink-100">The generated verification code will valid for 10 minutes</p>
        </div>

        <div className="flex items-center gap-x-2">
          <Button
            loading={generatingPin}
            type="button"
            color="default"
            onClick={handleResend}
            disabled={timeLeft > 0}
          >
            Resend Code
          </Button>
          {timeLeft > 0 && <p className="text-ink-100">in {timeLeft}s</p>}
        </div>
        <ChangeVerificationMethod
          textAndBtnInSameLine
          changeStep={changeStep}
          message="Can’t receive codes?"
          buttonText="Try another way"
        />
      </div>
    </div>
  );
}

export default FedexValidatePinForm;
