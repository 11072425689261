import React from 'react';
import { Button, Alert } from 'easyship-components';
import { useNavigate } from 'react-router-dom';
import { routesList } from '@/router/routes-list';
import { twMerge } from 'tailwind-merge';

/**
 * This component is responsible for the generic UI styles of the courier forms
 */
interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  onGoBack?: () => void;
  onSubmit: () => void;
  hasValidationErrors: boolean;
  submitBtnText?: string;
  isSubmitting?: boolean;
}

export const Form = ({
  onGoBack,
  onSubmit,
  children,
  className,
  hasValidationErrors,
  submitBtnText,
  isSubmitting,
  ...props
}: FormProps): React.ReactElement => {
  const navigate = useNavigate();

  const onBackAction = () => {
    if (onGoBack) {
      onGoBack();
      return;
    }
    navigate(routesList.courierList);
  };
  return (
    <form className={twMerge('flex gap-y-3 flex-wrap', className)} {...props}>
      {children}
      {hasValidationErrors && (
        <Alert severity="error" className="w-full">
          Please make sure all required fields are properly filled.
        </Alert>
      )}
      <div className="flex justify-between w-full">
        <Button type="button" flat onClick={onBackAction}>
          back
        </Button>
        <Button loading={!!isSubmitting} color="primary" onClick={onSubmit} className="px-10">
          {submitBtnText || 'connect'}
        </Button>
      </div>
    </form>
  );
};
