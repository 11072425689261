import CountryAddressRules from '@/models/CountryAddressRules';
import { PartialDeep } from 'type-fest';

const defaultCountryRules: CountryAddressRules = {
  postalCode: { translationKey: 'Postal Code', shouldFetchCityState: false },
  city: { translationKey: 'City', isDropdown: false },
  state: { isDisabled: false, isRequired: false, isVisible: true, isDropdown: false },
  hkDistrict: { isVisibleForSender: false },
};

const countryRules: Record<string, PartialDeep<CountryAddressRules>> = {
  hk: {
    state: { isVisible: false },
    hkDistrict: { isVisibleForSender: true },
  },
  us: {
    postalCode: { translationKey: 'Zip Code', shouldFetchCityState: true },
    state: { isDisabled: true, isRequired: true },
  },
  au: {
    postalCode: { shouldFetchCityState: true },
    city: { translationKey: 'Suburb', isDropdown: true },
    state: { isRequired: true, isDropdown: true },
  },
  ca: {
    postalCode: { shouldFetchCityState: true },
    state: { isDisabled: true, isRequired: true },
  },
  mx: {
    postalCode: { shouldFetchCityState: true },
    state: { isDisabled: true, isRequired: true },
  },
  nz: {
    postalCode: { shouldFetchCityState: true },
    city: { isDropdown: true },
  },
  pr: {
    postalCode: { shouldFetchCityState: true },
  },
  th: {
    state: { isRequired: true },
  },
  cn: {
    state: { isRequired: true },
  },
  id: {
    state: { isRequired: true },
  },
  my: {
    state: { isRequired: true },
  },
  vn: {
    state: { isRequired: true },
  },
};

function getCountryRules(countryCode: string | undefined): CountryAddressRules {
  if (!countryCode) return defaultCountryRules;
  return deepMerge(defaultCountryRules, countryRules[countryCode.toLowerCase()]) as CountryAddressRules;
}

export { getCountryRules };

function deepMerge<T>(...objects: (T | undefined)[]): T {
  const result = {} as T;
  
  objects.forEach(source => {
    if (!source) return;
    
    Object.keys(source).forEach(key => {
      const sourceValue = (source as any)[key];
      const targetValue = (result as any)[key];
      
      if (
        sourceValue && 
        typeof sourceValue === 'object' && 
        !Array.isArray(sourceValue) &&
        targetValue && 
        typeof targetValue === 'object' && 
        !Array.isArray(targetValue)
      ) {
        (result as any)[key] = deepMerge(targetValue, sourceValue);
      } else {
        (result as any)[key] = sourceValue;
      }
    });
  });
  
  return result;
}
