import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { API } from '@/utils/apiConstants';
import { SubmitCourierData } from './useSubmitCourierMutation';

export const useFedexGeneratePinMutation = () => {
  return useMutation({
    mutationFn: ({ companyId, courier, data }: SubmitCourierData): Promise<object> =>
      axios.post(
        `${API.baseEndpoint}/api/v1/companies/${companyId}/courier_accounts/${courier}/generate_pin_code`,
        { account: data },
      ),
  });
};
