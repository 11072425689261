import { useState, useEffect, useCallback } from 'react';

const useCountdown = (targetTime: number) => {
  const [timeLeft, setTimeLeft] = useState(targetTime);
  const [isRunning, setIsRunning] = useState(false);

  const start = useCallback(() => {
    setIsRunning(true);
    setTimeLeft(targetTime);
  }, [targetTime]);

  const reset = useCallback(() => {
    setIsRunning(false);
    setTimeLeft(targetTime);
  }, [targetTime]);

  useEffect(() => {
    if (!isRunning || timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [isRunning, timeLeft]);

  return { timeLeft, isRunning, start, reset };
};

export default useCountdown;