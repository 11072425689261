import { UseQueryResult, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API } from '@/utils/apiConstants';
import { Country } from '@/models/Address';

export const GET_CURRENCIES_KEY = 'GET_CURRENCIES_KEY';

export const useCurrencies = (): UseQueryResult<string[]> => {
  return useQuery<string[]>({
    queryKey: [GET_CURRENCIES_KEY],
    queryFn: async (): Promise<string[]> => {
      const { data } = await axios.get(`${API.baseEndpoint}/api/v1/countries`);
      const currencies = new Set<string>();
      data?.countries?.forEach((country: Country) => {
        if (country?.currency_code) {
          currencies.add(country?.currency_code);
        }
      });
      return Array.from(currencies).sort();
    },
    staleTime: Infinity,
  });
};
