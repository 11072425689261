import { twMerge } from 'tailwind-merge';

interface ChangeVerificationMethodProps {
  changeStep: () => void;
  message: string;
  buttonText: string;
  textAndBtnInSameLine?: boolean;
}

function ChangeVerificationMethod({
  changeStep,
  message,
  buttonText,
  textAndBtnInSameLine,
}: ChangeVerificationMethodProps) {
  return (
    <div className={twMerge(textAndBtnInSameLine && 'flex gap-x-1 items-center')}>
      <p className={twMerge('text-base text-ink-900', !textAndBtnInSameLine && 'mb-2')}>
        {message}
      </p>
      <button
        className="text-base text-blue-500 hover:text-blue-700"
        type="button"
        onClick={changeStep}
      >
        {buttonText}
      </button>
    </div>
  );
}

export default ChangeVerificationMethod;
