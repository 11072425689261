import { FormValues, formSchema } from './schema';
import { CourierFormComponentProps } from '../types';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/Form';
import { ControlledInput } from '@/components/ControlledInput';
import { API } from '@/utils/apiConstants';

export const COMPATIBLE_UMBRELLA_NAMES = {
  auPostBusiness: 'Australia Post MyPost Business',
  auPostDemand: 'Australia Post On Demand',
} satisfies Record<string, string>;

export const FormComponent = ({
  umbrellaName,
  onSuccess,
}: CourierFormComponentProps<FormValues>) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  const submitForm = async (data: FormValues) => {
    if (umbrellaName === COMPATIBLE_UMBRELLA_NAMES.auPostBusiness) {
      window.localStorage.setItem('myPostBusinessUserAccountName', data.nickname);

      const redirectUrl = `${API.appUrl}/redirect&umbrella_name=mypost-business`;
      const myPostUrl = `${API.mypostBusinessRedirect}?client_id=${API.easyshipClientId}&redirect_uri=${redirectUrl}&state=${API.mypostBusinessState}`;

      window.top ? (window.top.location.href = myPostUrl) : (window.location.href = myPostUrl);
    } else {
      onSuccess(data);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit(submitForm)}
      hasValidationErrors={Object.keys(errors).length !== 0}
    >
      {umbrellaName === COMPATIBLE_UMBRELLA_NAMES.auPostBusiness && (
        <ControlledInput
          name="nickname"
          control={control}
          label="Account Name"
          placeholder="e.g. MyCarrierAccount 01"
          statusText="The account name will be used to identify your account"
        />
      )}

      <ControlledInput
        name="accountNumber"
        control={control}
        label="Account Number"
        placeholder="e.g. 123456789"
      />

      <ControlledInput name="password" control={control} label="Password" type="password" />

      <ControlledInput name="userId" control={control} label="Australia Post API Key" />
    </Form>
  );
};
