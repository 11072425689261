import {
  Controller,
  FieldPath,
  FieldValues,
  Control,
  PathValue,
  Path,
} from "react-hook-form";
import { Option, Position, Select } from "easyship-components";

type ControlledSelectProps<T extends FieldValues> = {
  label: string;
  placeholder?: string;
  required?: boolean;
  options: Option<string>[];
  statusText?: string;
  control: Control<T>;
  name: FieldPath<T>;
  position?: Position;
  disabled?: boolean;
  defaultValue?: string;
};

export const ControlledSelect = <T extends FieldValues>({
  name,
  control,
  label,
  placeholder,
  required,
  statusText,
  options,
  position,
  disabled,
  defaultValue
}: ControlledSelectProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={(defaultValue || "") as PathValue<T, Path<T>>}
      render={({ field: { ref, value, onChange }, fieldState }) => (
        <Select
          placeholder={placeholder}
          options={options}
          label={label}
          required={required}
          value={options.find((c) => c.value === value)}
          onChange={(_ev, option) => onChange(option?.value)}
          disabled={disabled}
          ref={ref}
          status={!!fieldState.error ? "error" : "default"}
          statusText={
            fieldState.error?.message || (
              <p className="text-ink-100">{statusText}</p>
            )
          }
          position={position}
        />
      )}
    />
  );
};
