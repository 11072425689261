import { ControlledRadioGroup } from '@/components/ControlledRadioGroup';
import { useFormContext } from 'react-hook-form';
import ChangeVerificationMethod from './ChangeVerificationMethod';
import { GeneratePinFormValues } from './schema';

interface FedexPinFormProps {
  changeStep: () => void;
}

function FedexGeneratePinForm({ changeStep }: FedexPinFormProps) {
  const { control } = useFormContext<GeneratePinFormValues>();

  return (
    <div className="flex flex-col gap-y-6">
      <p className="text-lg">Get Verification Code</p>
      <p>
        Verify your account by receiving a verification code via your registered email or phone
        number in FedEx.
      </p>
      <>
        <div className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-2">
            <p>How do you want to get codes?</p>
            <ControlledRadioGroup
              name="validation_option"
              control={control}
              required
              defaultValue="email"
              options={[
                { label: 'Email', value: 'email' },
                { label: 'Text Message', value: 'sms' },
              ]}
            />
          </div>
          <ChangeVerificationMethod
            changeStep={changeStep}
            buttonText="Provide invoice information"
            message="Can’t verify? Try another option below."
          />
        </div>
      </>
    </div>
  );
}

export default FedexGeneratePinForm;
