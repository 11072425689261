import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import * as CC from 'change-case';
import { API } from '@/utils/apiConstants';

export interface SubmitCourierData {
  companyId: string;
  courier: string;
  data: object;
}

export interface SubmitCourierResponse {
  canadaPostTokenId?: string;
  id?: string;
  authState?: string;
}

export const useSubmitCourierMutation = () => {
  return useMutation({
    mutationFn: async ({
      companyId,
      courier,
      data,
    }: SubmitCourierData): Promise<SubmitCourierResponse> => {
      const formattedData: { [k: string]: string } = Object.keys(data).reduce(
        (acc, key) => {
          const keyWithNumber = key.split(/(\d+)/).join('_');
          const formattedKey = CC.snakeCase(keyWithNumber);
          acc[formattedKey] = data[key as keyof typeof data];
          return acc;
        },
        {} as { [k: string]: string },
      );

      const response = await axios.post(
        `${API.baseEndpoint}/api/v1/companies/${companyId}/courier_accounts/${courier === 'ups' ? 'ups-ready-oauth' : courier}`,
        { account: formattedData },
      );

      const formattedResponse: SubmitCourierResponse = Object.keys(response.data).reduce(
        (acc, key) => {
          const formattedKey = CC.camelCase(key);
          acc[formattedKey as keyof SubmitCourierResponse] = response.data[key];
          return acc;
        },
        {} as SubmitCourierResponse,
      );

      return formattedResponse;
    },
  });
};
