import { useCurrentUser } from '@/hooks/useCurrentUser';
import { useSetUpsCodeMutation } from '@/hooks/useSetUpsCode';
import { useSubmitCourierMutation } from '@/hooks/useSubmitCourierMutation';
import { useUpdateCourierMutation } from '@/hooks/useUpdateCourierMutation';
import { routesList } from '@/router/routes-list';
import axios from 'axios';
import { Pulse } from 'easyship-components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const RedirectPage = (): React.ReactElement => {
  const { data: user } = useCurrentUser();
  const navigate = useNavigate();
  const { mutate: submitCourierMutation } = useSubmitCourierMutation();
  const { mutate: updateCourierMutation } = useUpdateCourierMutation();
  const { mutate: setUpsCodeMutation } = useSetUpsCodeMutation();
  const urlParams = new URLSearchParams(window.location.search);
  const redirectUrl = window.localStorage.getItem('ESredirectUrl');
  const umbrellaName = urlParams.get('umbrella_name') ?? '';
  const isAuPostMyPostBusiness = umbrellaName === 'mypost-business';
  const isCanadaPost = umbrellaName === 'canada-post';
  const isUps = umbrellaName === 'ups';

  useEffect(() => {
    if (user?.companyId) {
      if (isCanadaPost) {
        processCanadaPost(user.companyId);
      } else if (isAuPostMyPostBusiness) {
        processMyPostBusiness(user.companyId);
      } else if (isUps) {
        processUps(user.companyId);
      } else {
        onSuccess();
      }
    } else {
      onError();
    }
  }, [user?.companyId]);

  const onSuccess = () => {
    window.localStorage.removeItem('ESredirectUrl');
    navigate(routesList.success, {
      state: { umbrellaName, redirectUrl },
    });
  };

  const onError = (error?: Error) => {
    window.localStorage.removeItem('ESredirectUrl');
    const message =
      error && axios.isAxiosError(error)
        ? error.response?.data?.status
        : 'An error has occurred while adding the courier account';
    navigate(routesList.error, {
      state: { message, redirectUrl, hideButton: true },
    });
  };

  const processMyPostBusiness = async (companyId: string) => {
    const countryDetails = user?.shippingCountries?.find((country) => country.name === 'Australia');

    const payload = {
      account: {
        umbrella_name: 'australia-post-mypost-business',
        code: urlParams.get('code'),
        nickname:
          urlParams.get('nickname') ?? window.localStorage.getItem('myPostBusinessUserAccountName'),
        origin_country_id: countryDetails?.id,
      },
    };
    submitCourierMutation(
      {
        companyId,
        courier: 'australia-post-mypost-business',
        data: payload,
      },
      {
        onSuccess,
        onError,
      },
    );
    window.localStorage.removeItem('myPostBusinessUserAccountName');
  };

  const processCanadaPost = async (companyId: string) => {
    const courierId = urlParams.get('courier_id');
    const registrationStatus = urlParams.get('registration-status');
    const tokenId = urlParams.get('token-id');

    if (registrationStatus !== 'SUCCESS' || !courierId) {
      navigate(routesList.error, {
        state: { redirectUrl },
      });
      return;
    }

    updateCourierMutation(
      {
        id: courierId,
        companyId,
        courier: umbrellaName,
        data: {
          umbrellaName,
          tokenId,
          registrationStatus,
        },
      },
      {
        onSuccess,
        onError,
      },
    );
  };

  const processUps = async (companyId: string) => {
    const courierAccountId = urlParams.get('courier_account_id');
    const code = urlParams.get('code');

    if (!courierAccountId || !code) {
      navigate(routesList.error, {
        state: { redirectUrl },
      });
      return;
    }

    setUpsCodeMutation(
      {
        companyId,
        courierAccountId,
        code,
      },
      {
        onSuccess,
        onError,
      },
    );
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-sky-300 text-ink-900">
      <Pulse />
      <p>Please wait while we setup your courier. This shouldn't take too long.</p>
    </div>
  );
};
