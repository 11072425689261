import { z } from 'zod';
import { Country } from '@/models/Address';

export const addressFormSchema = (country: Country) => z.object({
  nickname: z.string().min(1, ''),
  accountNumber: z.string().min(1, ''),
  countryCode: z.string().min(1, ''),
  postalCode: z.string().min(1, ''),
  city: z.string().min(1, ''),
  state: z.string().optional(),
  line1: z.string().min(1, ''),
  line2: z.string().optional(),
}).superRefine((values, ctx) => {
  if (country.isStateRequired && !values.state) {
    ctx.addIssue({
      message: '',
      code: z.ZodIssueCode.custom,
      path: ['state'],
    });
  }
});

export type AddressFormValues = z.infer<ReturnType<typeof addressFormSchema>>;

export const generatePinFormSchema = z.object({
  validation_option: z.string().min(1, ''),
});

export type GeneratePinFormValues = z.infer<typeof generatePinFormSchema>;

const DAYS_BACK = 180;
const minDate = new Date(Date.now() - DAYS_BACK * 24 * 60 * 60 * 1000); // 180 days ago
const maxDate = new Date();

export const invoiceFormSchema = z.object({
  number: z.string().min(1, ''),
  date: z.string().refine(
    (value) => {
      const selectedDate = new Date(value);
      return selectedDate >= minDate && selectedDate <= maxDate;
    },
    {
      message: '',
    },
  ),
  currency: z.string().min(1, ''),
  amount: z.string().min(1, ''),
});

export type InvoiceFormValues = z.infer<typeof invoiceFormSchema>;

export const validatePinFormSchema = z.object({
  pin_code: z.string().min(1, ''),
});

export type ValidatePinFormValues = z.infer<typeof validatePinFormSchema>;
