import axios from 'axios';
import FedexAddressForm from './FedexAddressForm';
import FedexInvoiceForm from './FedexInvoiceForm';
import FedexGeneratePinForm from './FedexGeneratePinForm';
import FedexValidatePinForm from './FedexValidatePinForm';
import { useState, useMemo, useCallback } from 'react';
import { PdfAgreement } from './pdfAgreement';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from '@/components/Form';
import { useSubmitCourierMutation } from '@/hooks/useSubmitCourierMutation';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert } from 'easyship-components';
import { useSubmitFedexMutation } from '@/hooks/useSubmitFedexMutation';
import { useFedexGeneratePinMutation } from '@/hooks/useFedexGeneratePinMutation';
import { FedexStepEnum, changeStepAndSchema } from '@/helpers/fedexStepsHelper';
import {
  AddressFormValues,
  GeneratePinFormValues,
  InvoiceFormValues,
  ValidatePinFormValues,
} from './schema';
import { Country } from '@/models/Address';

export const COMPATIBLE_UMBRELLA_NAMES = {
  fedex: 'FedEx',
  fedexCrossBorder: 'FedEx Cross Border',
  fedexParcelcast: 'FedEx-Parcelcast',
} satisfies Record<string, string>;

interface FedexFormProps {
  onSuccess: (data: object) => void;
  companyId: string;
  country: Country;
}

export const FormComponent = ({ onSuccess, companyId, country }: FedexFormProps) => {
  const [currentStep, setCurrentStep] = useState<FedexStepEnum>(FedexStepEnum.EULA);
  const [errorMsg, setErrorMsg] = useState('');
  const [fedexCourierId, setFedexCourierId] = useState('');

  const { prevStep, alternateStep, schema, submitBtnText } = useMemo(() => {
    return changeStepAndSchema(country)?.[currentStep] ?? {};
  }, [currentStep]);

  const acceptAgreement = () => {
    setCurrentStep(FedexStepEnum.FORM);
    window.scrollTo(0, 0);
  };

  const handleBack = useCallback(() => {
    setCurrentStep((currentStep) => (currentStep !== FedexStepEnum.EULA ? prevStep : currentStep));
    setErrorMsg('');
  }, [prevStep]);

  const changeStep = useCallback(() => {
    if (alternateStep) {
      setCurrentStep(alternateStep);
      setErrorMsg('');
    }
  }, [alternateStep]);

  const methods = useForm<
    AddressFormValues | InvoiceFormValues | GeneratePinFormValues | ValidatePinFormValues
  >({
    resolver: schema ? zodResolver(schema) : undefined,
    defaultValues: {
      date: new Date().toISOString().split('T')[0],
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    getValues,
  } = methods;

  const { mutate: submitAddressForm, isPending: addressFormSubmitting } =
    useSubmitCourierMutation();
  const { mutate: connectFedexAccount, isPending: fedexAccountConnecting } =
    useSubmitFedexMutation();
  const { mutate: generatePin, isPending: generatingPin } = useFedexGeneratePinMutation();

  const onSubmitError = (error: Error) => {
    const message = axios.isAxiosError(error)
      ? (error.response?.data?.status ?? 'An error has occurred while adding the courier account')
      : 'An error has occurred while adding the courier account';
    setErrorMsg(message);
  };

  const onAddressFormSubmit = (response: Record<string, any>) => {
    setCurrentStep(FedexStepEnum.INVOICE);
    setErrorMsg('');
    setFedexCourierId(response?.id ?? '');
  };

  const onGeneratePinSuccess = () => {
    setCurrentStep(FedexStepEnum.VALIDATE_PIN);
    setErrorMsg('');
  };

  function onSubmit(data: Record<string, any>) {
    if (currentStep === FedexStepEnum.FORM) {
      submitAddressForm(
        {
          companyId,
          courier: 'fedex-v1',
          data: { ...data, originCountryId: country.id || "" },
        },
        { onSuccess: onAddressFormSubmit, onError: onSubmitError },
      );
    }
    if (currentStep === FedexStepEnum.INVOICE || currentStep === FedexStepEnum.VALIDATE_PIN) {
      const payload = {
        nickname: getValues('nickname'),
        validation_option: getValues('validation_option') || 'invoice',
        pin_code: currentStep === FedexStepEnum.INVOICE ? null : data?.pin_code,
        invoice_details: currentStep === FedexStepEnum.INVOICE ? { ...data } : {},
      };
      connectFedexAccount(
        { companyId, courier: fedexCourierId, data: payload },
        { onSuccess: onSuccess, onError: onSubmitError },
      );
    }
    if (currentStep === FedexStepEnum.PIN_CHOICE) {
      generatePin(
        { companyId, courier: fedexCourierId, data },
        { onSuccess: onGeneratePinSuccess, onError: onSubmitError },
      );
    }
  }

  return (
    <>
      {currentStep === FedexStepEnum.EULA ? (
        <PdfAgreement
          umbrellaName={COMPATIBLE_UMBRELLA_NAMES.fedex}
          acceptAgreement={acceptAgreement}
          countryName={country.name}
        />
      ) : (
        <FormProvider {...methods}>
          <Form
            onGoBack={handleBack}
            onSubmit={handleSubmit(onSubmit)}
            hasValidationErrors={Object.keys(errors).length !== 0}
            submitBtnText={submitBtnText}
            isSubmitting={addressFormSubmitting || fedexAccountConnecting || generatingPin}
          >
            {(currentStep === FedexStepEnum.FORM && <FedexAddressForm country={country} />) ||
              (currentStep === FedexStepEnum.INVOICE && (
                <FedexInvoiceForm changeStep={changeStep} />
              )) ||
              (currentStep === FedexStepEnum.PIN_CHOICE && (
                <FedexGeneratePinForm changeStep={changeStep} />
              )) ||
              (currentStep === FedexStepEnum.VALIDATE_PIN && (
                <FedexValidatePinForm
                  changeStep={changeStep}
                  companyId={companyId}
                  fedexCourierId={fedexCourierId}
                />
              ))}
            {errorMsg && Object.keys(errors).length === 0 && (
              <Alert severity="error" className="w-full">
                {errorMsg}
              </Alert>
            )}
            <p className="text-xs text-ink-500 italic mt-3">
              FedEx service marks are owned by Federal Express Corporation and are used by
              permission.
            </p>
          </Form>
        </FormProvider>
      )}
    </>
  );
};
