import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { RadioGroup, Radio } from 'easyship-components';

type ControlledRadioGroupProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Pick<ControllerProps<TFieldValues, TName>, 'name' | 'control' | 'defaultValue'> & RadioProps;

interface RadioProps {
  options: { label: string; value: string }[];
  required: boolean;
}

export const ControlledRadioGroup = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  options,
  name,
  required,
  defaultValue,
}: ControlledRadioGroupProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      defaultValue={defaultValue}
      render={({ field }) => (
        <RadioGroup {...field} className="space-y-2">
          {options.map((option) => (
            <Radio
              key={option.value}
              label={option.label}
              value={option.value}
              checked={field.value === option.value}
            />
          ))}
        </RadioGroup>
      )}
    />
  );
};