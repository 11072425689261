import { COMPATIBLE_UMBRELLA_NAMES } from './FedexForm';
import { generatePath, useNavigate } from 'react-router-dom';
import { routesList } from '@/router/routes-list';
import { Separator } from '@/components/Separator';
import { Button } from 'easyship-components';
import { API } from '@/utils/apiConstants';

interface PdfAgreementProps {
  umbrellaName: string;
  acceptAgreement: () => void;
  countryName: string;
}

export const PdfAgreement = ({ umbrellaName, acceptAgreement, countryName }: PdfAgreementProps) => {
  const navigate = useNavigate();

  const getFedexEulaPdf = () => {
    let fileName = 'fedex-eula-distribution-agreement';

    if (umbrellaName === COMPATIBLE_UMBRELLA_NAMES.fedexCrossBorder) {
      fileName = 'fedex-cross-border-eula';
    } else {
      fileName = 'fedex-eula-v2';
    }

    return `${API.appStorage}/courier-docs/${fileName}.pdf`;
  };

  const goBack = () => {
    navigate(generatePath(routesList.courierList));
  };

  return (
    <>
      <p className="mb-4">
        Authenticate your{' '}
        <strong>
          {umbrellaName} {countryName}
        </strong>{' '}
        account to start shipping with your own negotiated rates.
      </p>
      <p>
        By connecting your FedEx account, you will no longer be eligible to use the discounted FedEx
        rates offered by Easyship.
      </p>
      <Separator />
      <p className="mb-4">In order to continue, you will need to agree to the FedEx EULA.</p>
      <iframe className="h-[380px] w-full" src={`${getFedexEulaPdf()}#zoom=FitW`}></iframe>
      <p className="text-xs mt-3 text-ink-500 italic mb-40">
        FedEx service marks are owned by Federal Express Corporation and are used by permission.
      </p>
      <Separator />

      <div className="flex justify-between w-full">
        <Button flat onClick={goBack}>
          back
        </Button>
        <Button color="primary" onClick={acceptAgreement} className="px-10">
          I accept
        </Button>
      </div>
    </>
  );
};
