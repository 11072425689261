export const API = {
  baseEndpoint: import.meta.env.VITE_API_BASE_URL,
  baseAuthEndpoint: import.meta.env.VITE_API_AUTH_BASE_URL,
  appStorage: import.meta.env.VITE_APP_STORAGE,
  appHelp: import.meta.env.VITE_APP_HELP,
  appCredentials: import.meta.env.VITE_APP_CREDENTIALS,
  appUrl: import.meta.env.VITE_APP_URL,
  mypostBusinessRedirect: import.meta.env.VITE_AU_MYPOST_BUSINESS_REDIRECT,
  easyshipClientId: import.meta.env.VITE_EASYSHIP_CLIENT_ID,
  mypostBusinessState: import.meta.env.VITE_AU_MYPOST_BUSINESS_STATE,
};
